.edit-nav {
  gap: 10px;
  display: flex;
  margin-left: 10px;
}

.edit-nav > li:hover {
  cursor: pointer;
  color: #bb9052;
}

.activeEditPage {
  color: #bb9052;
}
