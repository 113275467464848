@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

#music-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 3em;
}

.videoPreviewContainer {
  position: relative;
}

.videoPreviewContainer:hover {
  cursor: pointer;
}

.videoPreviewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoPreviewContainer:hover .videoPreviewOverlay {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.632);
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
}

.videoLabel {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  color: #bb9052;
}

.selected-video {
  margin-top: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* #music-videos-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
}

.musicVideoContainer {
  width: 350px;
  height: 220px;
}

.videoPreviewContainer {
  position: relative;
}

.videoPreviewContainer:hover {
  cursor: pointer;
}

.videoPreviewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoPreviewContainer:hover .videoPreviewOverlay {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.632);
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
}

.videoLabel {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  color: #bb9052;
}

.videoDescription {
}

.selected-video {
  margin-top: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

@media only screen and (min-width: 769px) {
  #music-font {
    width: 36%;
  }

  #music-videos-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
  }

  .musicVideoContainer {
    width: 350px;
  }

  .videoDescription {
  }
}
