.footer-container {
  background-color: #212121;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 350px;
}
#footer-bottom div:first-child {
  background-color: #bc9152;
  height: 8vh;
}
#footer-bottom div:nth-child(2) {
  background-color: #212121;
  height: 10vh;
}
#footer-nav {
  margin-bottom: 0;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-li {
  color: #bc9152;
  margin-bottom: 4px;
}

.footer-li:active {
  color: white;
}
#social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 5vh;
  margin-right: 10px;
}

.icon:hover {
  cursor: pointer;
}

@media only screen and (min-width: 769px) {
  #footer-nav {
    flex-direction: row;
    padding: 4em;
  }
  .footer-li:hover {
    text-decoration: none;
    color: white;
  }
  .footer-li:not(:last-child) {
    margin-right: 1em;
    border-right: solid 1px rgb(92, 92, 92);
    padding-right: 1em;
  }
  .icon {
    height: 4vh;
    margin-right: 16px;
  }
}
