@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');

#contact-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 3em;
}

#contact-img {
  width: 100%;
}

#contact-form-header {
  text-align: left;
  font-family: 'Playfair Display', serif;
}

#contact-form-header > h2 {
  text-align: left;
}

.email-recieved-message {
  height: 30px;
  font-family: 'Lato', sans-serif;
  font-size: 1.25em;
  line-height: 2em;
}

@media only screen and (min-width: 769px) {
  #contact-font {
    width: 38%;
  }
}
