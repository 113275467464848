@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');

#welcome-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
#alex-piano {
  width: 80%;
  display: block;
  margin: auto;
  margin-bottom: 2em;
  margin-top: 2em;
}
.container {
  display: block;
  margin: auto;
  position: relative;
  top: 0;
}
.home-description {
  margin: auto;
  text-align: center;
}
.home-description > h2 {
  font-family: 'Playfair Display', serif;
  margin-bottom: 1em;
}
.home-description > p {
  font-family: 'Lato', sans-serif;
}
.container-fluid {
  margin-top: 5%;
}

#read-more {
  margin: auto;
  margin-bottom: 2em;
  margin-top: 2em;
}
#read-more a {
  color: black;
  display: inline-block;
  border: solid 2px #bc9152;
  font-size: 1em;
  padding: 1em;
  padding-right: 2em;
  padding-left: 2em;
  border-radius: 15px;
  font-family: 'Lato', sans-serif;
}
#read-more a:hover {
  background-color: #bc9152;
  text-decoration: none;
  color: white;
}

#read-more a:active {
  background-color: #bc9152;
  text-decoration: none;
}

@media only screen and (min-width: 769px) {
  #welcome-font {
    width: 40%;
    margin-top: 3em;
  }
  .home-description {
    padding: 20px;
  }
  .home-about-content {
    margin-top: 2em;
    margin-bottom: 3em;
  }
  #alex-piano {
    width: 100%;
  }
  .container-fluid {
    margin-top: 0;
  }
}
