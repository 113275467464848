@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

#form-container {
  margin-top: 2em;
}

.page-header {
  font-family: 'Playfair Display', serif;
  text-align: center;
}

.lead {
  font-size: 18px !important;
  color: #bc9152;
}
label {
  font-family: 'Lato', sans-serif;
}

#studio-submit {
  border: 2px solid #bc9152;
  border-radius: 15px;
  padding: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
  font-family: 'Lato', sans-serif;
}
#studio-submit:hover {
  background-color: #bc9152;
  color: white;
}
.form-control {
  border: solid 2px #202020a7 !important;
  border-radius: 10px !important;
  font-family: 'Lato', sans-serif !important;
}
#select-wrapper {
  border-radius: 10px !important;
}

input:focus {
  border: 2px solid #bc9152 !important;
}
textarea:focus {
  border: 2px solid #bc9152 !important;
}
#student-information {
  margin-top: 1em;
}
small {
  font-family: 'Lato', sans-serif;
  font-size: 15px !important;
  color: #bc9152;
}
@media only screen and (min-width: 769px) {
  #form-container {
    margin-top: 3em;
  }

  #brook-recital {
    margin-top: 5em;
    width: 450px;
  }
}
