@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:100&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

#mobile-nav-container {
  /* background-color: #212121; */
  padding: 10px;
  cursor: pointer;
}
#toggle {
  padding: 10px;
  float: right;
}
span {
  display: block;
  background-color: white;
  border-radius: 2px;
  height: 3px;
  width: 2.3em;
  margin-bottom: 8px;
}

#nav-logo {
  width: 80px;
}

#main-logo {
  width: 50px;
}

#logo-container {
  transform-style: preserve-3d;
}
#logo-container:hover {
  animation: spin 1s linear;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

#navbar-ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #131313a7; */
}

#navbar-ul li:first-child {
  margin-top: 13px;
}

#navbar-ul li {
  list-style-type: none;
  text-decoration: none;
  margin-bottom: 10px;
}

#navbar-ul li a {
  color: white;
  font-size: 2em;
  font-family: 'Alegreya Sans', sans-serif;
}

#navbar-ul li a:hover {
  color: #bc9152;
  text-decoration: none;
}

#navbar-ul li a:active {
  color: #bc9152;
  text-decoration: none;
}

@media only screen and (min-width: 769px) {
  #navbar-container {
    padding: 1em;
    margin-bottom: 15px;
  }
  #main-logo {
    width: 90px;
  }

  #navbar-ul {
    flex-direction: row;
    justify-content: center;
    height: 100px;
    margin-bottom: 0;
  }
  #navbar-ul li:first-child {
    margin-top: 0px;
  }
  #navbar-ul li {
    margin-bottom: 0px;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  #navbar-ul li a:hover {
    font-weight: bolder;
    font-size: 2.1em;
    transition: font-size 1s;
  }
}
