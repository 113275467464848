@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2.3em;
  margin-bottom: 1em;
  text-align: center;
}

p {
  font-family: 'Lato', sans-serif;
  font-size: 1.25em;
  line-height: 2em;
}

#alex-img {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 1.8em;
  margin-bottom: 1.6em;
}

@media only screen and (min-width: 769px) {
  #bio-words {
    width: 50%;
  }
  .content {
    padding-top: 50px;
  }

  #alex-img {
    float: left;
    width: 60%;
    shape-margin: 2rem;
    shape-outside: url('../../assets/images/alex.png');
  }

  #bio-name {
    font-size: 2em;
    white-space: nowrap;
    display: inline;
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
  }
}
