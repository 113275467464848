@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:100&display=swap');

.home-nav-photo {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.home-nav-div {
  position: relative;
  padding: 0 !important;
}
.home-nav-div .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  display: none;
  color: #fff;
  font-size: 2.5em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.666);
  outline: 3px solid #bc9152;
  outline-offset: -15px;
}
.nav-label {
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
}
.nav-description {
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 0.6em;
  margin-top: 1em;
  letter-spacing: 1.5px;
  border: solid 1px #bc9152;
  padding: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 15px;
}
.nav-description:hover {
  background-color: #bc9152;
}
@media only screen and (min-width: 769px) {
  .home-nav-photo {
    height: 700px;
  }
  .home-nav-div .after {
    height: 700px;
  }
  .home-nav-div:hover .after:hover {
    height: 700px;
    background: rgba(0, 0, 0, 0.399);
    cursor: pointer;
  }
  .home-nav-div:hover .after:hover .nav-description {
    background-color: #bc9152;
  }
}
