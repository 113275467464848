@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather:300&display=swap');

#photos-container {
  display: flex;
  flex-direction: column;
}

.gallery-photo {
  display: block;
  margin: auto;
  width: 90vw;
  height: 35vh;
  object-fit: cover;
  margin-bottom: 5px;
}
.selected-image {
  width: 90vw;
  object-fit: contain;
  padding-left: 10px;
  padding-right: 10px;
}
.selected-div {
  margin-top: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#view {
  display: none;
}

.description {
  color: white;
  margin-top: 1em;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  text-align: center;
}

.spinner-border {
  margin-top: 100px;
}

@media only screen and (min-width: 769px) {
  .photo-wrapper {
    position: relative;
    flex: 1 0 21%;
    margin: 5px;
  }
  .photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .photo-wrapper:hover .photo-overlay {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.632);
  }

  #view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: #bc9152;
    border: solid 2px #bc9152;
    font-size: 1em;
    padding: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 15px;
    font-family: 'Lato', sans-serif;
  }
  .photo-wrapper:hover #view {
    display: block;
    cursor: pointer;
  }

  #photos-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .selected-image {
    width: 85vw;
    height: 80vh;
    object-fit: contain;
    padding-left: 10px;
    padding-right: 10px;
  }
  .gallery-photo {
    width: 100%;
    height: 100%;
  }
  .gallery-photo:hover {
    cursor: pointer;
  }
}
