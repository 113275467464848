@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
#studio-info-container {
  margin-bottom: '2em';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
}
#studio {
  width: 100%;
  object-fit: cover;
  padding: 1em;
}
#studio-description {
  margin-bottom: 0.5em;
}
#studio-title {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 2em;
}
@media only screen and (min-width: 769px) {
  #studio-info-container {
    /* margin-top: 2em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #studio {
    width: 100%;
    height: 45vh;
  }
  #studio-description {
    width: 100%;
  }
}
