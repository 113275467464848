#schedule-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 3em;
}

@media only screen and (min-width: 769px) {
  #schedule-font {
    width: 40%;
  }
}
