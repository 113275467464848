#studio-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 2em;
}

@media only screen and (min-width: 769px) {
  #studio-font {
    width: 55%;
    margin-top: 2em;
    /* margin-bottom: -2em; */
  }
}
