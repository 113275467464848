@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

#carousel {
  margin-bottom: 2em;
}
#home-video {
  display: block;
  width: 80vw;
  height: 40vh;
  margin: auto;
}

#explore-font {
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 80px;
}
#event-divider {
  display: none;
}
#home-events {
  margin-top: 70px;
}
.home-event {
  display: flex;
  margin-bottom: 2px;
}
.home-event img {
  display: none;
}
.home-event-date {
  background-color: #212121;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-event-month {
  display: table;
  margin: 0 auto;
  font-size: 1em;
  text-align: center;
  padding: 0;
  margin-top: 3px;
  color: white;
  background-color: #212121;
}
.home-event-day {
  display: table;
  margin: 0 auto;
  font-size: 1.5em;
  text-align: center;
  padding: 0;
  color: white;
  background-color: #212121;
}
.home-event-details {
  background-color: rgb(101, 100, 100);
  display: flex;
  flex-direction: column;
  flex: 3;
  color: white;
  padding: 2%;
}
.home-event-details h4 {
  font-family: 'Playfair Display', serif;
}
.home-event-details h5 {
  color: #bc9152;
}
@media only screen and (min-width: 769px) {
  #home-video {
    width: 60vw;
    height: 60vh;
    margin-top: 7%;
  }

  #explore-font {
    width: 40%;
  }
  #carousel {
    display: block;
    margin: auto;
    width: 60%;
  }
  #event-divider {
    display: block;
    height: 7vh;
    background: #bc9252e2;
    margin-top: 6em;
  }
  /* #home-upcoming {
    text-align: center;
    margin-top: 1em;
  } */
  #home-events {
    margin-top: 150px;
    display: flex;
    padding: 20px;
  }
  .home-event {
    width: 25%;
    flex-direction: column;
    margin: 20px;
  }
  .home-event img {
    display: block;
    position: relative;
    width: 100%;
    height: 50%;
    object-fit: cover;

    align-self: center;
    flex: 0 0 auto;
  }
  .home-event-date {
    flex: auto;
  }
  .home-event-details {
    padding: 10%;
  }
}
