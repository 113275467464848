video {
  width: 100vw;
  object-fit: cover;
  height: 88vh;
}
#video-container {
  z-index: 1;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
#video-nav {
  position: absolute;
  bottom: -0.56rem;
  width: 100%;
  /* z-index: 2; */
}

@media only screen and (min-width: 768px) {
  video {
    position: relative;
    height: 93vh;
    /* outline: 3px solid #d4af37b8;
    outline-offset: -20px; */
  }
}
