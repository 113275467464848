@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:100&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

#schedule-events-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#scheduleTypeContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.scheduleEventsTitle {
  font-size: 3em;
  font-family: 'Alegreya Sans', sans-serif;
  text-align: center;
  margin-bottom: 30px;
}

.scheduleEventsTitle:hover {
  cursor: pointer;
  color: #bc9152;
}

.currentType {
  color: #bc9152;
  font-weight: 800;
  border-bottom: 3px solid #bc9152;
}

.currentType:hover {
  /* color: black; */
  cursor: default;
}

.scheduleEvent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eventTitle {
  font-size: 2em;
}

.eventDate {
  margin-top: 15px;
  font-size: 1.25em;
}

.eventLocation {
  margin-top: 10px;
}

.eventDescription {
  margin-top: 10px;
  max-width: 500px;
  text-align: center;
  line-height: 1.75em;
}

.eventDivider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingSoon {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 1.2em;
}
